import React from 'react';
import {useOutlet} from 'reconnect.js';
import * as NavUtil from '../../Utils/NavUtil';
import Horizontal from './horizontal';
import Vertical from './vertical';

export default function SiteFooter(props) {
  useOutlet('footerConfig');
  const footerConfig = NavUtil.getFooterConfig(props);

  if (footerConfig.style === 'horizontal') {
    return <Horizontal {...props} />;
  } else if (footerConfig.style === 'vertical') {
    return <Vertical {...props} />;
  }

  return <Vertical {...props} />;
}
