import * as tw from 'rev.sdk.js/Locale/tw';

const labels = {
  ...tw.labels,
  intro: '介紹',
  spec: '規格',
  remark: '備註',
  share_link: '複製分享連結',
  confirm: '確認',
  customer_support: '聯絡我們',
  customer_support_title: '問題類型',
  customer_support_content: '詢問內容',
  name: '姓名',
  phone: '聯絡電話',
  email: '信箱',
  updated: '更新時間',
  next_article: '上一篇',
  prev_article: '下一篇',
};

export {labels};
