import React from 'react';
import styled from 'styled-components';

function CopyrightRemark(props) {
  return (
    <Wrapper>
      <p>
        本網站發佈內容皆由當事人同意分享使用，保養品使用效果因人而異，實際狀況需視個人體質或由專業醫師審慎評估而定。本網站禁止任何非授權網際網路服務業者轉錄本網路資訊內容供人點閱；但以網路搜尋或超連結等方式，進入網址(域)直接點閱者，不在此限。版權所有
        © 微媞生技股份有限公司《未經授權同意 請勿節錄或轉載本站圖文》
        <a target="_blank" href="/privacy-policy">
          定型化契約
        </a>
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > p {
    font-size: 0.8rem;
    color: #888;

    & > a {
      margin-left: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export default CopyrightRemark;
