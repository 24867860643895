import React from 'react';
import styled from 'styled-components';

export default function PleaseWait(props) {
  const {title, msg} = props;
  return (
    <Wrapper>
      <h2>{title || '自動登入中'}</h2>
      <p>{msg || '請稍後...'}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h2 {
    font-size: 2rem;
  }
  & > p {
    font-size: 1.2rem;
    color: #ccc;
  }
`;
