import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as JStorageUtil from 'rev.sdk.js/Utils/JStorageUtil';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import {Modal, Form, Select, Button, message} from 'antd';
import * as AppActions from '../../AppActions';
const {Option} = Select;

getNewOutlet('multi-order-update-modal', null, {autoDelete: false});

function showAdminMultiOrderModal(data = {}) {
  getOutlet('multi-order-update-modal').update(data);
}

function AdminMultiOrderModal(props) {
  const [data, setData] = useOutlet('multi-order-update-modal');
  const [selectedOrders] = useOutlet('selected-orders');
  const visiable = !!data;
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const validation =
      Object.values(values).filter((value) => value === undefined).length <= 0;

    if (!validation) {
      message.warning('欄位不可為空。');
      return;
    }

    AppActions.setLoading(true);
    try {
      const actions = selectedOrders.map((order) => ({
        method: 'update_one',
        payload: {
          query: {_id: {$oid: order.id}},
          data: JStorageUtil.removeAutoFields(values),
        },
      }));

      await JStorage.bulkWriteDocuments('order', actions);
      message.success('批次建立成功。');

      setData(null);
      form.resetFields();
    } catch (err) {
      console.warn(err);
      message.error('批次修改失敗。');
    }

    data.refresh();
    AppActions.setLoading(false);
  };

  return (
    <Modal
      visible={visiable}
      title={data?.title}
      destroyOnClose={true}
      footer={null}
      onCancel={() => showAdminMultiOrderModal(null)}>
      <Wrapper>
        <Form form={form} onFinish={onFinish}>
          <Form.Item>
            <h4>{`已選取 ${selectedOrders.length} 張訂單`}</h4>
          </Form.Item>

          {data?.name === 'status' && (
            <Form.Item name={data.name} label="訂單狀態">
              <Select allowClear style={{width: 200}} placeholder="請選擇">
                <Option value="waiting">待處理</Option>
                <Option value="processing">處理中</Option>
                <Option value="done">已完成</Option>
                <Option value="canceled">已取消</Option>
                {/* <Option value="return_applied">退貨審核通過</Option> */}
                {/* <Option value="return_completed">已退款</Option> */}
              </Select>
            </Form.Item>
          )}

          {data?.name === 'logistics_status' && (
            <Form.Item name={data.name} label="物流狀態">
              <Select allowClear style={{width: 200}} placeholder="請選擇">
                <Option value="pending">備貨中</Option>
                <Option value="center_delivered">已出貨</Option>
                <Option value="in_delivery">運送中</Option>
                <Option value="store_delivered">到店未取貨</Option>
                <Option value="delivered">已送達</Option>
                <Option value="error">地址錯誤</Option>
                <Option value="exception">尚未定義</Option>
              </Select>
            </Form.Item>
          )}

          <Form.Item>
            <Button htmlType="submit" type="primary">
              確認
            </Button>
          </Form.Item>
        </Form>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div``;

export default AdminMultiOrderModal;
export {showAdminMultiOrderModal};
