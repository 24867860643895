import React from 'react';
import * as StockUtil from '../../Utils/StockUtil';

export default function AdminProductStockSummary(props) {
  const {record} = props;

  if (!record.stock_type || record.stock_type === 'always') {
    return '常備品';
  } else if (record.stock_type === 'total') {
    return '總量庫存';
  } else if (record.stock_type === 'period') {
    const [startDate, endDate] = StockUtil.getStartEndDate(record);
    if (!startDate || !endDate) {
      return `期間庫存設定有誤`;
    } else {
      return (
        <div>
          <div>期間庫存</div>
          <div>- 開始: {startDate.toLocaleString()}</div>
          <div>- 結束: {endDate.toLocaleString()}</div>
        </div>
      );
    }
  }

  return '---';
}
