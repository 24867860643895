import React from 'react';
import {Helmet} from 'react-helmet';
import {getOutlet} from 'reconnect.js';
import LoginModal from '../../Components/LoginModal';
import ResetPasswordModal from '../../Components/ResetPasswordModal';
import GlobalSpinner from '../../Components/GlobalSpinner';

export function renderGlobalHtmlHelmet() {
  return <Helmet></Helmet>;
}

export function renderGlobalModals() {
  return (
    <>
      <LoginModal />
      <ResetPasswordModal />
      <GlobalSpinner />
    </>
  );
}
