import React from 'react';
import styled from 'styled-components';
import {CloseCircle} from '@styled-icons/ionicons-outline/CloseCircle';

export default function MobileNavDrawer(props) {
  const {visible, setVisible} = props;

  return (
    <>
      <Backdrop visible={visible} onClick={() => setVisible(false)} />
      <MobileContent visible={visible}>
        <div className="header">
          <h2>Menu</h2>
          <button onClick={() => setVisible(false)}>
            <CloseCircle size={26} color="#b4b4b4" />
          </button>
        </div>

        {props.children}
      </MobileContent>
    </>
  );
}

const Backdrop = styled.div`
  z-index: 101;
  position: fixed;
  right: 0;
  top: var(--topNavBarHeight);
  width: 100vw;
  height: 100vh;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  transition: 300ms;
`;

const MobileContent = styled.div`
  z-index: 101;
  position: fixed;
  right: 0;
  top: var(--topNavBarHeight);
  width: 194px;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.31);
  display: flex;
  flex-direction: column;
  transition: 300ms;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: ${(props) =>
    props.visible ? 'translateX(0px)' : 'translateX(200px)'};

  & > .header {
    display: flex;
    justify-content: space-between;
    padding: 14px;
    border-bottom: 1px solid #b4b4b4;

    & > h2 {
      font-weight: bold;
      color: var(--primaryColor);
    }

    & > button {
      border: 0px;
      background-color: transparent;
    }
  }

  & > .nav-items {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 250px);
    overflow: auto;

    & > * {
      &.default {
        padding: 14px 20px;
        font-size: 1rem;
        color: #646464;
      }

      &.default-child {
        padding: 14px 20px 14px 35px;
        font-size: 1rem;
        color: #646464;
      }

      &.selected {
        color: var(--primaryColor);
      }
    }
  }
`;
