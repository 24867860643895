import {getOutlet} from 'reconnect.js';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as User from 'rev.sdk.js/Actions/User';
import * as jwt from 'rev.sdk.js/Utils/jwt';
import tracker from 'rev.sdk.js/Utils/Tracker';
import Config from '../../data.json';
import {setLoading, getDefaultCheckoutFormSpec} from './index.js';
/* $$__CODEGEN_EXTRA_APPACTION_HEAD__$$ */

const AppActionsOverride = {};
const UserOutlet = getOutlet('user');

//////////////////////////////////////////////////
// Override existing AppActions
AppActionsOverride.doCustomThing = async (...args) => {
  console.log(args);
};

/* $$__CODEGEN_EXTRA_APPACTION_TAIL__$$ */

AppActionsOverride.onCartLoaded = (...args) => {
  const cart = args[0];
  const checkoutFormSpec = getDefaultCheckoutFormSpec();

  const defaultUser = {
    buyer_name: cart.buyer_name || UserOutlet.getValue().data.name || '',
    buyer_email: cart.buyer_email || UserOutlet.getValue().data.email || '',
    buyer_phone: cart.buyer_phone || UserOutlet.getValue().data.phone || '',
    buyer_zip: cart.buyer_zip || UserOutlet.getValue().data.zip || '',
    buyer_city: cart.buyer_city || UserOutlet.getValue().data.city || '',
    buyer_district:
      cart.buyer_district || UserOutlet.getValue().data.district || '',
    buyer_address:
      cart.buyer_address || UserOutlet.getValue().data.address || '',
  };

  const updateConfig = {
    ...cart,
    ...defaultUser,
  };

  if (!updateConfig.invoice_type) {
    updateConfig.invoice_type = Cart.INVOICE_TYPE.B2C_PROVIDER;
  }

  return {
    updateConfig,
    checkoutFormSpec,
  };
};

AppActionsOverride.onLoginResult = async (...args) => {
  let [err, result] = args;

  if (!err) {
    try {
      setLoading(true);
      const isAdmin = result.grp.split(':').indexOf('admin') !== -1;
      if (!isAdmin) {
        const queryKey = Config.jstoreVersion !== 'v1' ? 'owner' : 'id';
        const profile = await JStorage.fetchOneDocument('user_profile', {
          [queryKey]: UserOutlet.getValue().username,
        });
        const privateProfile = await User.getPrivateProfile();

        UserOutlet.update({
          ...UserOutlet.getValue(),
          data: {
            ...profile,
            email: privateProfile.email,
            points: privateProfile.points,
            provider: privateProfile.provider,
            ...privateProfile.extra,
          },
        });

        await jwt.decodeToken(UserOutlet.getValue().token);
        tracker.login({user: UserOutlet.getValue()});
        await Cart.fetchCart({initial_clear: true, initial_raise: true});
      }
    } catch (ex) {
      console.warn('onLoginResult ex', ex);
    } finally {
      setLoading(false);
    }
  }
};

AppActionsOverride.clientJStorageFetch = async (
  collection,
  {cat, sort, search, q, page},
) => {
  //"q" can defined custom query by project
  let catQuery = cat ? {labels: {$regex: cat}} : {};
  const sortValue = sort ? [sort] : ['priority', '-created'];
  const pagingValue = page;
  const extraQueries = {};
  let projection = null;

  if (collection === 'product') {
    extraQueries.public = true;

    extraQueries['$or'] = [
      {stock_type: {$exists: false}},
      {stock_type: 'always'},
      {stock_type: 'total'},
      {
        stock_type: 'period',
        stock_start_date: {
          $lte: new Date().toISOString().split('T')[0],
        },
        stock_end_date: {
          $gte: new Date().toISOString().split('T')[0],
        },
      },
    ];

    if (search) {
      extraQueries['$or'] = extraQueries['$or'].map((query) => {
        return {
          ...query,
          $or: [{name: {$regex: search}}, {searchText: {$regex: search}}],
        };
      });
    }
  } else if (collection === 'Article_Default') {
    if (!cat) {
      catQuery = {$or: [{label: 'blog'}, {labels: ['blog']}]};
    } else {
      catQuery = {
        $or: [{label: {$regex: cat}}, {labels: {$in: [{$regex: cat}]}}],
      };
    }
    projection = {content: 0};
  }

  const resp = await JStorage.fetchDocuments(
    collection,
    {
      ...catQuery,
      ...extraQueries,
    },
    sortValue,
    pagingValue,
    projection, // if we're fetching Article, ignore the content
    {anonymous: true},
  );

  return resp;
};

export default AppActionsOverride;

//////////////////////////////////////////////////
// Extra custom actions
function sayHello() {
  console.log('hello, this is custom section, custom it');
}

export {sayHello};
