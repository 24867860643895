import React from 'react';
import {Helmet} from 'react-helmet';
import Config from '../../../data.json';

export function renderHtmlHelmet(props) {
  const {location} = props;

  if (location.pathname.indexOf('admin') !== -1) {
    return (
      <Helmet>
        <title>{Config.site_name}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.min.js"
          integrity="sha512-IM60GPudO4jk+ZQm3UlJgKHhXQi5pNDM6mP+pLKL968YgkHMc7He3aGJOVHEZ9rJ4vAaEtJ8W6SKa7Qq4inzBA=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <script src="https://cdn.jsdelivr.net/jsbarcode/3.3.20/JsBarcode.all.min.js" />
        <script src="https://cdn.jsdelivr.net/npm/davidshimjs-qrcodejs@0.0.2/qrcode.min.js" />
        <script src="https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js" />
        <link href="/prism/prism.css" rel="stylesheet" />
        <script src="/prism/prism.js" />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>{Config.site_name}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${Config.gaId}`}
      />
    </Helmet>
  );
}

export function renderProfileCustomSection(sectionId, activePath, extraData) {
  // let {action, order_id, order_number} = extraData;
  return null;
}
