import {getOutlet} from 'reconnect.js';

export function getNavItems(props) {
  const _navbarConfig = getOutlet('navbarConfig').getValue();
  const defaultNavItems = [
    {name: '商品', path: '/products'},
    {name: '文章', path: '/articles'},
    {name: '品牌介紹', path: '/about'},
  ];

  const navbarConfig =
    _navbarConfig || props.pageContext?.prebuiltSiteConfig?.navbar_config;

  const navItems =
    (navbarConfig &&
      navbarConfig.entries?.length > 0 &&
      navbarConfig.entries) ||
    defaultNavItems;

  return navItems;
}

export function isNavLinkSelected(navItem) {
  function sliceTailSlash(path) {
    if (path[path.length - 1] === '/') {
      return path.slice(0, -1);
    }

    return path;
  }

  if (
    typeof window !== 'undefined' &&
    typeof window.location?.pathname === 'string'
  ) {
    return (
      sliceTailSlash(`${window.location.pathname}${window.location.search}`) ===
      sliceTailSlash(navItem.path)
    );
  }
  return false;
}

export function getNavLinkExtraProps(navItem) {
  if (
    navItem.path.indexOf('/profile') === 0 ||
    navItem.path.indexOf('/checkout') === 0 ||
    navItem.path.indexOf('/products') === 0 ||
    navItem.path.indexOf('/articles') === 0
  ) {
    return {
      loading: true,
    };
  }
  return {
    loading: 600,
  };
}

export function getFooterNavItems(props) {
  const defaultNavItems = [
    {name: '商品', path: '/products'},
    {name: '文章', path: '/articles'},
    {name: '品牌', path: '/about'},
  ];

  const _footerConfig = getOutlet('footerConfig').getValue();
  const footerConfig =
    _footerConfig || props.pageContext?.prebuiltSiteConfig?.footer_config;

  const navItems =
    (footerConfig &&
      footerConfig.entries?.length > 0 &&
      footerConfig.entries) ||
    defaultNavItems;

  return navItems;
}

export function getFooterConfig(props) {
  const _footerConfig = getOutlet('footerConfig').getValue();

  const footerConfig =
    _footerConfig || props.pageContext?.prebuiltSiteConfig?.footer_config;

  return footerConfig || {};
}
