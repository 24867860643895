import React from 'react';
import styled from 'styled-components';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import {Modal, Form, InputNumber, Select, Input, Button, message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Admin from 'rev.sdk.js/Actions/Admin';
import AdminProductStockSummary from '../../Generators/AdminResource/AdminProductStockSummary';
import * as AppActions from '../../AppActions';

getNewOutlet('product-stock-update-modal', null, {autoDelete: false});

function showAdminProductStockModal(
  data = {action: '', record: {}, refresh: () => null},
) {
  getOutlet('product-stock-update-modal').update(data);
}

function AdminProductStockModal(props) {
  const [data, setData] = useOutlet('product-stock-update-modal');
  const visible = !!data;
  const [form] = Form.useForm();

  const onFinish = async (formData) => {
    let {action, record, refresh} = data;

    if (
      (action === 'stock_add_action' || action === 'stock_subtract_action') &&
      !formData.value
    ) {
      message.warning('請填寫數量。');
      return;
    }

    if (
      action !== 'stock_reset_action' &&
      action !== 'stock_set_action' &&
      !formData.reason
    ) {
      message.warning('請填寫原因。');
      return;
    }

    AppActions.setLoading(true);

    try {
      if (action === 'stock_add_action') {
        await Admin.updateProductStockAmount({
          type: 'mod',
          product_id: record.id,
          value: formData.value,
          data: {reason: formData.reason, note: formData.note},
        });
      } else if (action === 'stock_subtract_action') {
        await Admin.updateProductStockAmount({
          type: 'mod',
          product_id: record.id,
          value: -Math.abs(formData.value),
          data: {reason: formData.reason, note: formData.note},
        });
      } else if (action === 'stock_reset_action') {
        await Admin.updateProductStockAmount({
          type: 'clear',
          product_id: record.id,
          value: 0,
        });
      } else if (action === 'stock_set_action') {
        await Admin.updateProductStockAmount({
          type: 'set',
          product_id: record.id,
          value: formData.stock_amount,
        });
      }

      if (action === 'stock_add_action' || action === 'stock_set_action') {
        if (formData.stock_sold_amount !== record.stock_sold_amount) {
          await JStorage.updateDocument(
            'product',
            {id: record.id},
            {
              stock_sold_amount: formData.stock_sold_amount,
            },
          );
        }
      }

      refresh();
      message.success('修改成功。');
      showAdminProductStockModal(null);
    } catch (err) {
      console.warn(err);
      message.err('發生錯誤。');
    } finally {
      AppActions.setLoading(false);
    }
  };

  const onError = (data) => {
    console.log(data);
  };

  return (
    <Modal
      visible={visible}
      destroyOnClose={true}
      title={`${data?.record.name} 庫存修改 ${(() => {
        switch (data?.action) {
          case 'stock_add_action':
            return '- 進貨';
          case 'stock_subtract_action':
            return '- 銷貨';
          case 'stock_reset_action':
            return '- 清零';
          case 'stock_set_action':
            return '- 設定';
          default:
            return '';
        }
      })()}`}
      footer={null}
      onCancel={() => showAdminProductStockModal(null)}>
      <FormWrapper>
        {data && (
          <Form
            form={form}
            layout="horizontal"
            initialValues={{
              value: 0,
              stock_amount: data.record.stock_amount || 0,
              stock_sold_amount: data.record.stock_sold_amount || 0,
              reason: '',
              note: '',
            }}
            onFinish={onFinish}
            onFinishFailed={onError}>
            <Form.Item label="庫存類型" style={{marginBottom: 10}}>
              <AdminProductStockSummary record={data?.record} />
            </Form.Item>
            <Form.Item label="庫存數量" style={{marginBottom: 10}}>
              {data.record.stock_type === 'always'
                ? '---'
                : data?.record.stock_amount}
            </Form.Item>
            <Form.Item label="已售出">
              {data.record.stock_type === 'always'
                ? '---'
                : data?.record.stock_sold_amount
                ? data.record.stock_sold_amount
                : '尚未設定'}
            </Form.Item>

            {data.action === 'stock_add_action' && (
              <>
                <Form.Item label="進貨數量" name="value">
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  label="原因"
                  name="reason"
                  style={{maxWidth: 200, width: '100%'}}>
                  <Select defaultValue="入庫">
                    <Select.Option value="入庫">入庫</Select.Option>
                    <Select.Option value="其他">其他</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="備註" name="note">
                  <Input.TextArea />
                </Form.Item>
                {!data?.record.stock_sold_amount && (
                  <Form.Item
                    label="已售出"
                    name="stock_sold_amount"
                    extra="首次進貨初始值為 0。">
                    <InputNumber />
                  </Form.Item>
                )}
              </>
            )}
            {data.action === 'stock_subtract_action' && (
              <>
                <Form.Item label={'銷貨數量'} name="value">
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  label="原因"
                  name="reason"
                  style={{maxWidth: 200, width: '100%'}}>
                  <Select defaultValue="銷貨">
                    <Select.Option value="瑕疵">瑕疵</Select.Option>
                    <Select.Option value="破損">破損</Select.Option>
                    <Select.Option value="贈送">贈送</Select.Option>
                    <Select.Option value="公關品">公關品</Select.Option>
                    <Select.Option value="遺失">遺失</Select.Option>
                    <Select.Option value="報廢">報廢</Select.Option>
                    <Select.Option value="轉倉">轉倉</Select.Option>
                    <Select.Option value="退貨">退貨</Select.Option>
                    <Select.Option value="其他">其他</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="備註" name="note">
                  <Input.TextArea />
                </Form.Item>
              </>
            )}
            {data.action === 'stock_reset_action' && (
              <Form.Item label={'庫存總量'} name="value">
                確認清零
              </Form.Item>
            )}
            {data.action === 'stock_set_action' && (
              <div>
                <Form.Item label="庫存總量" name="stock_amount">
                  <InputNumber />
                </Form.Item>
                <Form.Item label="已售出" name="stock_sold_amount">
                  <InputNumber />
                </Form.Item>
              </div>
            )}

            <Form.Item>
              <Button htmlType="submit" type="primary">
                送出
              </Button>
              <Button
                style={{marginLeft: 10}}
                onClick={() => {
                  form.resetFields();
                }}
                type="dashed">
                清除
              </Button>
            </Form.Item>
          </Form>
        )}
      </FormWrapper>
    </Modal>
  );
}

const FormWrapper = styled.div`
  & .info {
    margin-bottom: 20px;
  }
`;

export default AdminProductStockModal;
export {showAdminProductStockModal};
