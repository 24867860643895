import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import {Steps} from 'antd';
import React from 'react';
import * as AppActions from '../../AppActions';
import styled from 'styled-components';
import {withLoginRequired} from 'rev.sdk.js/Components/LoginRequired';
import SiteNavBar from '../SiteNavBar';
import LoginRequired from '../LoginRequired';
import useIsInApp from 'rev.sdk.js/Hooks/useIsInApp';

function CheckoutLayout(props) {
  const isInApp = useIsInApp();
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');
  const {style = {}} = props;
  const route = props.location.pathname;
  const params = qs.parse(props.location.search);

  function renderCustomSection(sectionId) {
    const subRoute = route.split('/')[2];
    let current = 0;

    if (subRoute === 'info') {
      current = 1;
    } else if (subRoute === 'review') {
      current = 2;
    }

    if (sectionId === 'B') {
      return (
        <Steps
          direction="horizontal"
          type="navigation"
          responsive={false}
          current={current}
          style={{marginBottom: 20}}>
          <Steps.Step title="購物車" />
          <Steps.Step title="寄送資訊" />
          <Steps.Step title="付款" />
        </Steps>
      );
    }

    return null;
  }

  return (
    <Wrapper id="rev-checkout-layout" isInApp={isInApp} style={{...style}}>
      {renderCustomSection('A')}

      <div className="content">
        {renderCustomSection('B')}

        {props.children}

        {renderCustomSection('J')}
      </div>

      {renderCustomSection('K')}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: ${({isInApp}) => (isInApp ? 0 : 'var(--topNavBarHeight)')};

  & > .content {
    max-width: var(--contentMaxWidth);
    min-height: var(--contentMinHeight);
    margin: 0 auto;
    padding: var(--basePadding);
  }

  @media only screen and (max-width: 480px) {
    &#rev-checkout-layout .ant-steps-navigation .ant-steps-item::after {
      display: none;
    }
  }
`;

export default withLoginRequired(CheckoutLayout, {
  admin: false,
  SiteNavBar,
  renderLoginRequired: LoginRequired,
});
