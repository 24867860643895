import {getOutlet} from 'reconnect.js';
import Config from '../../../data.json';

export function getAdminRoutes() {
  const config = getOutlet('config');

  return [
    {name: '首頁', path: '/admin/'},
    {name: '顧客訊息', path: '/admin/mails'},
    {name: '會員', path: '/admin/users/'},
    {
      name: '商品',
      path: '__group-product',
      subroutes: [
        {name: '商品全覽', path: '/admin/products/'},
        {name: '庫存管理', path: '/admin/stocks/'},
        {name: '庫存管理紀錄', path: '/admin/stock-records'},
        {name: '封存商品', path: '/admin/archived_products'},
        {name: '多語系(Beta)', path: '/admin/i18n-products/'},
      ],
    },
    {
      name: '訂單',
      path: '__group-order',
      subroutes: [
        {name: '訂單全覽', path: '/admin/orders/'},
        {name: '待處理', path: '/admin/orders/waiting/'},
        {name: '處理中', path: '/admin/orders/processing/'},
        {name: '已完成', path: '/admin/orders/done/'},
        {name: '已取消', path: '/admin/orders/canceled/'},
        {name: '未結單', path: '/admin/orders/checkout/'},
        {name: '退貨紀錄', path: '/admin/orders/return/'},
        {name: '建立客製化訂單', path: 'admin-custom-order'},
        {name: '批次處理', path: '/admin/orders/bulk-write/'},
        {name: '批次匯出(Beta)', path: '/admin/orders/export/'},
      ],
    },
    {
      name: '優惠',
      path: '__group-discount',
      subroutes: [
        {name: '優惠券', path: '/admin/coupons/'},
        {name: '滿額折扣', path: '/admin/discount-list/'},
        {name: '組合折扣', path: ''},
      ],
    },
    {
      name: '文章',
      path: '__group-article',
      subroutes: [
        {name: '文章全覽', path: '/admin/articles/'},
        {name: '多語系(Beta)', path: '/admin/i18n-articles'},
      ],
    },
    {name: '網站設定', path: '/admin/site/'},
    {name: '頁面建置(Beta)', path: '/admin/pages'},
    {name: '前台手動更新', path: '/admin/rebuild-history'},
    {
      name: '上傳工具',
      path: '__group-uploader',
      subroutes: [
        {name: '圖片上傳', path: 'image-uploader'},
        {name: '檔案上傳', path: 'file-uploader'},
      ],
    },
    {name: '重設密碼', path: 'reset-password'},
    {name: '登出', path: 'logout'},
  ]
    .filter(
      (_) =>
        !!_ &&
        (_.path === '/admin/rebuild-history'
          ? Config.allowGithubRebuild
          : true),
    )
    .map((route) => {
      if (route.name === '優惠' && !!config.getValue()) {
        let combine_discount_index = route.subroutes.findIndex(
          (subroute) => subroute.name === '組合折扣',
        );

        route.subroutes[
          combine_discount_index
        ].path = `/admin/combine-discount/?action=detail&id=${
          config.getValue().id
        }`;
      }

      return route;
    });
}
