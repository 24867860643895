import React from 'react';
import {useOutlet} from 'reconnect.js';
import {default as FileUploadModalSDK} from 'rev.sdk.js/Components/FileUploadModal';

function FileUploadModal(props) {
  const [fileUploadModal, setFileUploadModal] = useOutlet('file-upload-modal');
  return (
    <FileUploadModalSDK
      visible={fileUploadModal}
      setVisible={setFileUploadModal}
      accept={fileUploadModal?.accept}
      preview={fileUploadModal?.preview}
    />
  );
}

export {FileUploadModal};
