import * as en from 'rev.sdk.js/Locale/en';

const labels = {
  ...en.labels,
  intro: 'Introduction',
  spec: 'Specification',
  remark: 'Remark',
  share_link: 'Share Link',
  confirm: 'Confirm',
  customer_support: 'Contact',
  customer_support_title: 'About what',
  customer_support_content: 'Detailed description',
  name: 'Name',
  phone: 'Phone',
  email: 'Email',
  updated: 'Updated time',
  next_article: 'Next Article',
  prev_article: 'Previous Article',
};

export {labels};
