import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import {showAdminSearchUserModal} from '../../Components/AdminSearchUserModal/index.js';

export default function AdminSelectUserWidget(props) {
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);

  React.useEffect(() => {
    const fetchRecordById = async (owner) => {
      setFetching(true);

      try {
        let nextProfile = {};
        const _profile = await Jstorage.fetchOneDocument('user_profile', {
          owner,
        });
        const _private_profile = await Jstorage.fetchOneDocument(
          'private_profile',
          {owner},
        );

        nextProfile = {
          ..._profile,
          email: _private_profile?.email || _profile?.email || '',
          points: _private_profile?.points || 0,
          provider: _private_profile?.provider || _profile?.provider || '',
        };

        setSelectedUser(nextProfile);
      } catch (err) {
        console.warn(err);
      }

      setFetching(false);
    };

    if (props.value) {
      fetchRecordById(props.value);
    }
  }, [props.value]);

  return (
    <Wrapper>
      <div>
        {fetching ? (
          '設定中...'
        ) : !props.value ? (
          '尚未設定會員'
        ) : !selectedUser ? (
          props.value
        ) : (
          <Button
            type="link"
            target="_blank"
            title="點選開啟新分頁編輯"
            href={`/admin/users/?action=detail&id=${selectedUser.id}`}>{`【 ${selectedUser.name} / ${selectedUser.phone} / ${selectedUser.email} / ${selectedUser.provider} 】`}</Button>
        )}
      </div>
      <Button
        onClick={() => {
          props.onChange('');
          setSelectedUser(null);
        }}
        style={{marginRight: 10}}>
        清除
      </Button>

      <Button
        type="primary"
        onClick={() =>
          showAdminSearchUserModal({
            onChange: (owner) => {
              props.onChange(owner);
            },
          })
        }>
        搜尋
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid #d9d9d9;
  padding: 15px;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;
