import React from 'react';
import SocialFloatingBtn from '../Components/SocialFloatingBtn';

export default function useLayoutInit(props) {
  const {location, pageContext} = props;
  React.useEffect(() => {
    // trigger only once
  }, []);

  React.useEffect(() => {
    // trigger when location change
  }, [location]);

  return {
    extraElement: <SocialFloatingBtn />,
  };
}
