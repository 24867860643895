import {css} from 'styled-components';

const OldCustomCss = `
--pageMaxWidth: 2000px;
--contentMaxWidth: 1240px;
--contentMinHeight: 600px;
--basePadding: 15px 20px;
--primaryColor: #b8425f;
--sectionPadding: 50px 100px;
--sectionMobilePadding: 20px;

min-height: 100vh;
display: flex;
flex-direction: column;

.title {
  font-size: 20px;
  color: var(--primaryColor);
  letter-spacing: 2px;
  font-weight: bold;

  &.mobile {
    font-size: 15px;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.subtitle {
  color: var(--primaryColor);
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: bold;

  &.mobile {
    font-size: 10px;
  }
}

.constrain-content {
  max-width: 900px;
  margin: 0 auto;
}

.section-dark {
  padding: var(--sectionPadding);
  background-color: #f9f8f7;

  &.mobile {
    padding: var(--sectionMobilePadding);
  }
}

.section {
  padding: var(--sectionPadding);

  &.mobile {
    padding: var(--sectionMobilePadding);
  }
}

.carousel-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  &.mobile {
    flex-direction: column;
  }
}

.content {
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 26px;
  white-space: break-spaces;
  color: #000;
}

.centered {
  margin: 0 auto;
  text-align: center;
}
`;

const revSiteNavBar = `
  #rev-SiteNavBar {
    box-shadow: rgb(0 0 0 / 5%) 0px 10px 20px;

    & > .logo-box > a > img {
      display: none;
    }

    & > .logo-box > a::before {
      content: '';
      display: inline-block;
      background-image: url('/images/logo.png');
      background-size: 90px 34px;
      width: 90px;
      height: 34px;
    }

  }
`;

const revSiteFooter = `
  #rev-SiteFooter {

    & > .content > .inner > .site-logo {
      display: none;
    }

    & > .content > .inner::before {
      content: '';
      display: inline-block;
      background-image: url('/images/logo.png');
      background-size: 109px 41px;
      width: 109px;
      height: 41px;
    }

    & > .content > .inner > .inner-right > .lang-selector {
      display: none;
    }

    & > .content > .copyright {
     color: #000000;
    }
  }
`;

const revProductListPage = `
  #rev-ProductListPage {
    margin-bottom: 50px;
    & .browser-header {
      margin: 0px 0px 20px 0px;
      & img {
        height: auto;
        object-fit: contain;
      }
    }

  }
`;

const revArticleListPage = `
  #rev-ArticleListPage {

    margin-top: 20px;
    margin-bottom: 60px;

    & > .browser-header {
      display: none;
    }

    .rev-FilterMenuTop {
      position: sticky;
      top: var(--topNavBarHeight);
      background-color: #ffffff;
      margin-bottom: 10px;
      z-index: 98;

      &.mobile {
        margin: 0px;
      }

      & > .header {
        & > .label {
          display: none;
        }

        &::before {
          content: '最新消息';
          width: 100%;
          text-align: center;
          font-size: 1.2rem;
          font-wieght: bold;
        }
      }

      & > .items {
        max-width: var(--contentMaxWidth);

        & > * {
          min-width: 100px;
          padding: 10px 15px;
          background-color: #ffffff;
          border: 1px solid #e8e8e8;
          box-shadow: none;
          transition: all 200ms;
          color: #727272;

          & > .header {
            font-size: inherit;
            color: #727272;
          }

          & > .header > .label {
            line-height: 22px;
          }

          &:hover,
          &:active {
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
          }
        }
      }

        & div.selected {
          background-color: var(--primaryColor);
          border: 1px solid var(--primaryColor);

          & > .header {
            font-size: inherit;
            color: #ffffff;
          }
        }
      }
    }
  }
`;

const vbAboutPage = `
  #vb-AboutPage {
    ${OldCustomCss};
  }
`;

const vbContactPage = `
  #vb-ContactPage {
    ${OldCustomCss};
  }
`;

const vbCheckoutPages = `
  #rev-CartPage,
  #rev-CheckoutInfoPage,
  #rev-CheckoutReviewPage {

    #checkout-btn {
      background-color: var(--primaryColor);
    }

    .discounts-info > div:nth-child(2) {
      font-size: 0.8rem;
    }

    .point {
      & > label {
        display: none;
      }
      &::before {
        content: '微媞幣';
      }
    }

    .rev-CheckoutForm {
      & > form > div:nth-child(3) {
        display: none;
      }
    }
  }
`;

export default css`
  ${revSiteNavBar};
  ${revSiteFooter};
  ${revProductListPage};
  ${revArticleListPage};
  ${vbAboutPage};
  ${vbContactPage};
  ${vbCheckoutPages}

  #rev-ProductListPage .rev-FilterMenuL2,
  #rev-ProductDetailPage .rev-FilterMenuL2 {
    position: sticky;
    top: 72px;
    margin: 0px 10px;

    & > .header {
      padding: 23px 0px;
      font-size: 1.25rem;

      & > .label {
        display: none;
      }

      &::before {
        content: '產品列表';
        flex: 1;
        font-size: 1.3rem;
      }
    }

    & > .L1 {
      background-color: transparent;
      border-radius: 0px;
      padding: 0px;
      margin-bottom: 0px;
      box-shadow: none;

      & > .header {
        padding: 10px 0;
        border-top: 1px solid #d0d0d0;
        font-size: 1rem;
        color: #000000;
      }

      & > .L1-items {
        border-top: 1px solid #d0d0d0;
        padding-left: 15px;
        overflow: hidden;

        & > .L2 {
          padding: 5px 10px;
        }

        & > .L2 > .header {
          font-size: 0.95rem;
          color: #6f6f6f;
        }

        & > .L2.selected > .header {
          font-size: 0.95rem;
          color: var(--primaryColor);
        }
      }

      &:last-child {
        border-bottom: 1px solid #d0d0d0;
      }
    }
  }

  /* $$__CODEGEN_EXTRA_STYLEDCSS__$$ */
`;
