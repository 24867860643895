import React, {Component} from 'react';
import styled from 'styled-components';
import {Line} from '@styled-icons/fa-brands/Line';
import {Messenger} from '@styled-icons/simple-icons/Messenger';

const BTN_ARR = [
  {
    icon: <Line size={35} className="line-icon" />,
    children: [
      {
        name: '微媞嚴選line@',
        link: 'https://line.me/R/ti/p/@v-care',
      },
      // {
      //   name: '台中line@',
      //   link: 'https://line.me/R/ti/p/@vbeauty-tc',
      // },
    ],
  },
  {
    icon: <Messenger size={33} className="fb-icon" />,
    children: [
      {
        name: '微媞嚴選messenger',
        link: 'https://m.me/106241877389187',
      },
      // {
      //   name: '台中messenger',
      //   link: 'https://m.me/vbeautyTC',
      // },
    ],
  },
];

class SocialFloatingBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBtnIdx: null,
    };

    // variable for mouse click to identify whether the event is happend outside the menu
    this._isClickOutside = true;
  }

  componentDidMount() {
    try {
      // prevent Reference Error: document in server side
      // true -> listener get the event first, false -> onClick attach get the event first
      document.addEventListener('click', this._mouseClick, false);
    } catch (err) {
      //
    }
  }

  componentWillUnmount() {
    try {
      document.removeEventListener('click', this._mouseClick, false);
    } catch (err) {
      //
    }
  }

  render() {
    let {css = ''} = this.props;
    let {selectedBtnIdx} = this.state;

    let isOpen = selectedBtnIdx === 0 || !!selectedBtnIdx;
    return (
      <Wrapper css={css} isOpen={isOpen}>
        <div className="relative-parent">
          <p style={{color: `var(--primaryColor)`}}>產品諮詢</p>

          {BTN_ARR.map((btn, idx) => (
            <div
              className="button-wrapper"
              onClick={() => {
                this._isClickOutside = false;
                this.setState({selectedBtnIdx: isOpen ? null : idx});
              }}>
              {btn.icon}
            </div>
          ))}
          <div
            className="toggle-menu"
            style={{bottom: 65 * (BTN_ARR.length - selectedBtnIdx - 1)}}>
            {(selectedBtnIdx === 0 || !!selectedBtnIdx) &&
              BTN_ARR[selectedBtnIdx].children.map((item, idx) => (
                <a href={item.link} target="_blank" rel="noreferrer">
                  <p>{item.name}</p>
                </a>
              ))}
          </div>
        </div>
      </Wrapper>
    );
  }

  _mouseClick = (e) => {
    if (!this._isClickOutside) {
      this._isClickOutside = true;
      return;
    }
    this.setState({selectedBtnIdx: null});
  };
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 0px;
  z-index: 1;

  .line-icon {
    color: #4b4b4b;
    transition: 0.2s;
    :hover {
      color: #53b434;
    }
  }
  .fb-icon {
    color: #4b4b4b;
    transition: 0.2s;
    :hover {
      color: #3a82f7;
    }
  }

  .relative-parent {
    position: relative;
    padding: 15px;
    border-radius: 20px 0px 0px 20px;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: 768px) {
      padding: 8px;
    }
  }
  & p {
    font-size: 13px;
    letter-spacing: 1.2px;
    text-align: center;
    margin: 5px 0px;
    white-space: nowrap;
  }

  & .button-wrapper {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-top: 0.5px solid #6b6e6a;

    @media only screen and (max-width: 768px) {
      padding: 8px;
    }
  }

  & .toggle-menu {
    z-index: -1;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    right: 0px;
    padding: 10px 40px;
    border-radius: 15px 0px 0px 15px;

    border-bottom: 1px solid #f1f1f1;
    box-shadow: 0px 0px 7px 1px rgba(132, 132, 132, 0.3);
    /* animation */
    transition: all 0.2s;
    pointer-events: ${(props) => (props.isOpen ? 'all' : 'none')};
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    right: ${(props) => (props.isOpen ? '80%' : '20%')};
    bottom: 0;
    @media only screen and (max-width: 768px) {
      padding: 10px 40px 10px 20px;
    }
    & .menu-item {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #4e4e4e;
      text-transform: uppercase;
      cursor: pointer;
      padding: 4px 20px;
      display: flex;
      align-items: center;
      background-color: #fff;
      transition: background-color 0.3s;

      & p {
        color: #000;
      }

      &:hover {
        background-color: rgba(200, 200, 200, 0.5);
      }

      & .bullet-point {
        width: 7px;
        height: 7px;
        background-color: #4e4e4e;
        margin-right: 8px;
      }
    }

    & .menu-item.active {
      color: #f36a26;

      & .bullet-point {
        background-color: #f36a26;
      }
    }
  }
  ${(props) => props.css}
`;

export default SocialFloatingBtn;
