import React from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.less';
import './index.css';
import './custom.css';
import useDetectDimension from 'rev.sdk.js/Hooks/useDetectDimension';
import * as CustomApp from '../Utils/CustomApp';
import * as CtxUtil from '../Utils/CtxUtil';
import styledCss from './styledCss';
import customStyledCss from './customStyledCss';

function Provider(props) {
  useDetectDimension();

  React.useEffect(() => {
    CtxUtil.onCtxRendered();
  }, []);

  return (
    <>
      {CustomApp.renderGlobalHtmlHelmet()}
      <Wrapper id="rev-AppContext">
        {props.children}
        {CustomApp.renderGlobalModals()}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  ${styledCss}
  ${customStyledCss}
`;

export {Provider};
