import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import appConfig from '../../../data.json';

export function getExtraLoginModalProps() {
  return {
    canFacebookLogin: appConfig.canFacebookLogin,
    canLineLogin: appConfig.canLineLogin,
    canGoogleLogin: appConfig.canGoogleLogin,
    canNormalLogin: appConfig.canNormalLogin,
    onForgotPasswordClick: null,
    onRegisterClick: null,
    renderExtra: () => {
      return (
        <Wrapper>
          <p className="text">擁有帳號即代表您同意</p>
          <p className="text">
            <Button
              type="link"
              size="small"
              target="_blank"
              href="/privacy-policy">
              隱私權政策
            </Button>
            及
            <Button
              type="link"
              size="small"
              target="_blank"
              href="/service-policy">
              服務條款
            </Button>
          </p>
        </Wrapper>
      );
    },
  };
}

const Wrapper = styled.div`
  margin-bottom: 20px;

  & > .text {
    margin-bottom: 5px;
    text-align: center;
    font-size: 13px;
  }
`;
